import { defineStore } from "pinia";
import Api from "../services/Api";
import { userStore } from "@/stores/user";

export const loanStore = defineStore("loan", {
  state: () => {
    return {
      loan: null,
      loans: [],
      memberLoans: [],
    };
  },
  getters: {
    pendingLoans: (state) => {
      return state.loans.filter((loan) => loan.status == "pending");
    },
    approvedLoans: (state) => {
      return state.loans.filter((loan) => loan.status == "approved");
    },
    deniedLoans: (state) => {
      return state.loans.filter((loan) => loan.status == "denied");
    },
  },
  actions: {
    getLoans() {
      return Api.getLoans()
        .then((res) => {
          this.loans = res.data.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    getSingleMemberLoans() {
      Api.getMemberLoans(userStore().user.id).then((res) => {
        this.memberLoans = res.data.data;
      });
    },
    createLoan(data) {
      return Api.createLoan(data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    editLoan(id, data) {
      return Api.editLoan(id, data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    deleteLoan(id) {
      return Api.deleteLoan(id)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
});
