<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">denied loans</div>
      </v-row>
      <v-row class="">
        <div class="">
          View all the denied loans and approve them if needed.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          label="Search"
          outlined
          dense
          v-model="search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <v-data-table
        class="text-capitalize elevation-10"
        :search="search"
        :items="loans"
        :headers="headers"
        item-key="id"
        no-data-text="There are not pending loans available"
      >
        <template v-slot:[`item.user`]="{ item }">
          {{ item.user.name }}
        </template>
        <template v-slot:[`item.start_date`]="{ item }">
          {{ item.start_date | moment("ddd, MMMM Do YYYY") }}
        </template>
        <template v-slot:[`item.end_date`]="{ item }">
          {{ item.end_date | moment("ddd, MMMM Do YYYY") }}
        </template>
        <template v-slot:[`item.loan_type`]="{ item }">
          {{ item.loan_type }} loan
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="primary"
            outlined
            rounded
            small
            @click="(approveDialog = true), (loanToEdit = item)"
            >approve</v-btn
          >
        </template>
      </v-data-table>

      <v-dialog v-model="approveDialog" width="auto">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">approve loan</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            <v-form v-model="form" ref="form">
              <v-textarea
                label="Reason"
                outlined
                dense
                :rules="[rules.required]"
                v-model="reason"
              ></v-textarea>

              <v-btn
                block
                color="primary"
                :loading="loading"
                :disabled="!form"
                @click="approveLoan()"
                >submit</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      v-model="snackBar"
      :color="snackBarData.success ? 'success' : 'error'"
    >
      <div class="text-center text-capitalize">
        {{ snackBarData.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import { loanStore } from "@/stores/loans.js";
export default {
  data() {
    return {
      receiptDialog: false,
      approveDialog: false,
      reason: "",
      search: "",
      headers: [
        {
          text: "member",
          value: "user",
          class: "text-uppercase black--text",
        },
        {
          text: "amount",
          value: "amount",
          class: "text-uppercase black--text",
        },
        {
          text: "start date",
          value: "start_date",
          class: "text-uppercase black--text",
        },
        {
          text: "end date",
          value: "end_date",
          class: "text-uppercase black--text",
        },
        {
          text: "loan type",
          value: "loan_type",
          class: "text-uppercase black--text",
        },
        {
          text: "actions",
          value: "actions",
          class: "text-uppercase black--text",
        },
      ],
      loading: false,
      form: false,
      rules: {
        required: (v) => !!v || "Required",
      },
      snackBar: false,
      snackBarData: {
        success: false,
        message: "",
      },
      loanToEdit: null,
    };
  },
  computed: {
    ...mapWritableState(loanStore, {
      loans: "deniedLoans",
    }),
  },
  methods: {
    ...mapActions(loanStore, ["getLoans", "editLoan"]),
    approveLoan() {
      this.loading = true;

      this.editLoan(this.loanToEdit.id, {
        amount: this.loanToEdit.amount,
        interest: this.loanToEdit.interest,
        start_date: this.loanToEdit.start_date,
        end_date: this.loanToEdit.end_date,
        status: "approved",
        reason: this.reason,
        loan_type: this.loanToEdit.loan_type,
        user_id: this.loanToEdit.user_id,
      })
        .then(() => {
          this.showSnackBar(true, "Loan approved succesfully");
          this.$refs.form.reset();
          this.getLoans();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
          this.approveDialog = false;
        });
    },
    showSnackBar(success, message) {
      this.snackBar = true;
      this.snackBarData.success = success;
      this.snackBarData.message = message;
    },
  },
  created() {
    this.getLoans();
  },
};
</script>

<style></style>
